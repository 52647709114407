<template>
  <div class="maint-board content">
    <h1 class="text-center">AWC Game hall system maintenance schedule<br>AWC 游戏大厅系统例行维护时间 (GMT+8)</h1>

    <p>
      * Please refer to the Maintenance Target of each <u><b>AWC System tab</b></u> for <span class="colored-text">AWC System</span> and <span class="colored-text">Game Platforms'</span> maintenance information.<br>
      * 请参阅 <u><b>AWC 各系统分页</b></u>中的 Maintenance Target 以获取本周 <span class="colored-text">AWC 系统</span>及<span class="colored-text">支援游戏平台</span>的维护讯息。
    </p>
    <br>
    <p><b>AWC System Maintenance Type<br>AWC 系统维护类型</b></p>
    <ul>
      <li>
        <b>DOWNTIME</b>: All of the games, back office and API from AWC can not be accessed.<br>
        <b>停機</b>：AWC 所提供的游戏、後台与 API 等服务皆无法访问。
      </li>
      <li>
        <b>NO DOWNTIME</b>: AWC will continue to provide services during the maintenance period, but there may be some unstable situations, it is recommended to try again later.<br>
        <b>不停機</b>：AWC 于维护期间内会持续提供服务，但可能出现一些不稳定的情况，建议稍候进行重试。
      </li>
    </ul>
    <br>
    <p><b>Game Platform Maintenance Type<br>游戏平台维护类型</b></p>
    <ul>
      <li>
        <b>DOWNTIME</b> maintenance only<br>
        皆为「<b>停机</b>」维护
      </li>
    </ul>

    <AntRow justify="end" :style="{ marginBottom: '16px' }">
      <AntCol>
        Schedule
        <AntSelect
          :value="selectedScheduleIdx"
          :options="scheduleOptions"
          @change="onScheduleChange"
        />
      </AntCol>
    </AntRow>
    <AntRow justify="center">
      <AntCol :span="selectedSource ? 24 : 'none'">
        <AntSpin :spinning="loading">
          <AntTabs v-if="selectedSource" v-model:activeKey="selectedSource">
            <AntTabPane v-for="source in chatSourceList" :key="source" :tab="source">
              <AntTable
                :rowKey="record => record.no"
                class="ant-table-striped"
                :columns="columns"
                :dataSource="maintData[selectedSource]"
                :pagination="false"
                bordered
              >
                <template #bodyCell="{ text, column, record,  }">
                  <template v-if="column.dataIndex === 'target'">
                    <div v-if="record.type === 'source'">
                      <b>AWC System</b> - {{ text }}<br>
                      {{ `(${record.downtime ? '' : 'No '}Downtime)` }}
                    </div>
                  </template>
                </template>
              </AntTable>
            </AntTabPane>
          </AntTabs>
          <AntAlert
            v-else-if="alertState.message"
            :message="alertState.message"
            :type="alertState.type"
            showIcon
          />
          <AntEmpty v-else>
            <template #description>
              No maintenance recently<br>
              目前没有维护
            </template>
          </AntEmpty>
        </AntSpin>
      </AntCol>
    </AntRow>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import moment from 'moment'

import {
  h,
  ref,
  reactive,
  computed,
  onMounted,
} from 'vue'

import { useRoute } from 'vue-router'

import {
  Row as AntRow,
  Col as AntCol,
  Select as AntSelect,
  Alert as AntAlert,
  Tabs as AntTabs,
  Table as AntTable,
  Spin as AntSpin,
  Empty as AntEmpty,
} from 'ant-design-vue'

import { getMaintInfo } from '@/api/maintInfo'

export default {
  name: 'MaintBoard',
  components: {
    AntRow,
    AntCol,
    AntSelect,
    AntAlert,
    AntTabs,
    AntTabPane: AntTabs.TabPane,
    AntTable,
    AntSpin,
    AntEmpty,
  },
  setup() {
    const route = useRoute()
    const { chatId } = route.params

    const chatSourceList = ref([])
    const selectedSource = ref()
    const selectedScheduleIdx = ref(1)
    const loading = ref(true)

    const alertState = reactive({})

    const nowMoment = moment()
    const scheduleOptions = Array.from({ length: 3 }).map((_, idx) => {
      // 11/25 00:01
      // 0 (-1~6): 11/13-11/19
      // 1  (1~7): 11/20-11/26
      // 2 (8~14): 11/27-12/03

      // 11/26 00:01 遇到周日時當周會變成下周
      // 0 (-1~6): 11/20-11/26
      // 1  (1~7): 11/27-12/03
      // 2 (8~14): 12/04-12/10
      if (nowMoment.day() === 0) nowMoment.subtract(1, 'day')
      return {
        value: idx,
        label: `${moment(nowMoment).day(idx * 7 - 6).format('YYYY/MM/DD')}~${moment(nowMoment).day(idx * 7).format('YYYY/MM/DD')}`,
      }
    })

    const maintData = ref([])

    const fetchMaintInfoAndSetState = async ({ week }) => {
      loading.value = true

      try {
        const data = await getMaintInfo({ chatId, week })
        loading.value = false

        if (data && typeof data === 'object') {
          if (alertState.message || alertState.type) {
            alertState.type = ''
            alertState.message = ''
          }

          chatSourceList.value = Object.keys(data).sort()
          selectedSource.value = chatSourceList.value[0] // eslint-disable-line prefer-destructuring

          chatSourceList.value.forEach((source) => {
            data[source] = data[source].sort((a, b) => {
              if (a.type === 'source' && b.type !== 'source') return -1 // a 排在 b 前面
              if (a.type !== 'source' && b.type === 'source') return 1 // a 排在 b 後面

              if (a.is_until_further_notice && a.type === 'game_with_no' && !(b.is_until_further_notice && b.type === 'game_with_no')) return 1
              if (!(a.is_until_further_notice && a.type === 'game_with_no') && b.is_until_further_notice && b.type === 'game_with_no') return -1

              return a.target.localeCompare(b.target)
            })
          })

          maintData.value = data
        } else {
          throw new Error('invalid response')
        }
      } catch (error) {
        loading.value = false
        console.log('error', error)
        if (error.message.includes(408)) {
          alertState.type = 'warning'
          alertState.message = 'Unable to proceed, please refresh page again.'
        } else {
          alertState.type = 'error'
          alertState.message = 'Error occurred (9999), please contact Technical Support.'
        }
      }
    }

    onMounted(() => fetchMaintInfoAndSetState({ week: selectedScheduleIdx.value }))

    const onScheduleChange = async (value) => {
      await fetchMaintInfoAndSetState({ week: value })
      selectedScheduleIdx.value = value
    }

    const getMaintText = ({ text: initialText, record, colDate: colDateMoment }) => {
      let text = initialText

      const {
        start_at,
        end_at,
        status,
        games,
      } = record

      const startMoment = moment(start_at)
      const mondayMoment = moment(nowMoment).day(selectedScheduleIdx.value * 7 - 6)

      const statusTextLookup = {
        'Maintenance Finished': '(Finished 已完成)',
        CANCEL: '(Cancelled 已取消)',
      }

      if (end_at) {
        const endMoment = moment(end_at)

        if (colDateMoment.isSame(start_at, 'day') || (startMoment.isBefore(mondayMoment, 'day') && colDateMoment.isSame(mondayMoment, 'day'))) {
          text = `${startMoment.format('M/D HH:mm')}~${endMoment.format('M/D HH:mm')}`

          if (Object.keys(statusTextLookup).includes(status)) text += ` ${statusTextLookup[status]}`

          if (games) {
            text = h('div', [
              h('p', text),
              ...games.map(({ en_name, ch_name, game_no }, i) => h('div', { key: i }, `${en_name} ${ch_name} (${game_no})`)),
            ])
          }
        } else if (colDateMoment.isSameOrBefore(end_at, 'day') && colDateMoment.isAfter(start_at, 'day')) {
          // obj.colSpan = 0
        } else {
          text = ''
        }
      } else { // until further notice
        if (colDateMoment.isSame(start_at, 'day') || (startMoment.isBefore(colDateMoment, 'day') && !colDateMoment.isAfter(mondayMoment, 'day'))) { // eslint-disable-line no-lonely-if
          text = `${startMoment.format('M/D HH:mm')}~further notice`

          if (Object.keys(statusTextLookup).includes(status)) text += ` ${statusTextLookup[status]}`

          if (games) {
            text = h('div', [
              h('p', text),
              ...games.map(({ en_name, ch_name, game_no }, i) => h('div', { key: i }, `${en_name} ${ch_name} (${game_no})`)),
            ])
          }
        } else if (colDateMoment.isBefore(start_at)) {
          text = ''
        }
      }

      return text
    }

    const getCellObj = ({ record, colDate: colDateMoment }) => {
      const obj = {}

      const {
        start_at,
        end_at,
        status,
        // is_until_further_notice,
      } = record

      const startMoment = moment(start_at)
      const mondayMoment = moment(nowMoment).day(selectedScheduleIdx.value * 7 - 6)
      // const end_at = ''

      if (end_at) {
        const endMoment = moment(end_at)

        if (colDateMoment.isSame(start_at, 'day') || (startMoment.isBefore(mondayMoment, 'day') && colDateMoment.isSame(mondayMoment, 'day'))) {
          obj.class = 'yellow-bg'
          obj.colSpan = moment(endMoment).set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          }).diff(startMoment.isBefore(mondayMoment, 'day') ? mondayMoment : startMoment, 'days') + 1

          if (['Maintenance Finished', 'CANCEL'].includes(status)) obj.class = 'grey-bg'
        } else if (colDateMoment.isSameOrBefore(end_at, 'day') && colDateMoment.isAfter(start_at, 'day')) {
          obj.colSpan = 0
        }
      } else { // until further notice
        if (colDateMoment.isSame(start_at, 'day') || (startMoment.isBefore(colDateMoment, 'day') && !colDateMoment.isAfter(mondayMoment, 'day'))) { // eslint-disable-line no-lonely-if
          const sundayMoment = moment(nowMoment).set({
            day: selectedScheduleIdx.value * 7,
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          })

          obj.colSpan = sundayMoment.diff(startMoment, 'days') + 1
          obj.class = 'yellow-bg'

          if (['Maintenance Finished', 'CANCEL'].includes(status)) obj.class = 'grey-bg'
        } else if (colDateMoment.isBefore(start_at)) {
          // obj.text = ''
        } else {
          obj.colSpan = 0
        }
      }

      return obj
    }

    const columns = computed(() => [
      {
        title: 'Maintenance Target',
        dataIndex: 'target',
        key: 'target',
      },
      ...[...Array(7)].map((_, idx) => {
        const colDateMoment = moment(nowMoment).day(selectedScheduleIdx.value * 7 - 6 + idx)
        return {
          title: colDateMoment.format('M/D ddd'),
          dataIndex: 'start_at',
          key: colDateMoment.format('ddd'),
          customRender: ({ text, record }) => getMaintText({
            text,
            record,
            colDate: colDateMoment,
          }),
          customCell: record => getCellObj({
            record,
            colDate: colDateMoment,
          }),
        }
      }),
    ])

    return {
      maintData,
      columns,
      selectedSource,
      selectedScheduleIdx,
      scheduleOptions,
      chatSourceList,
      loading,
      alertState,
      onScheduleChange,
    }
  },
}

/* eslint-enable camelcase */
</script>

<style>
.maint-board .ant-table-tbody > tr.ant-table-row:hover > td, .maint-board .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: #e6f7ff;
}

.maint-board.content {
  font-size: 16px;
  padding: 60px;
}

.maint-board .yellow-bg {
  background: #fff7e6;
}

.maint-board .grey-bg {
  background: #f8f8f8;
}

.maint-board .colored-text {
  color: #1890ff;
  font-weight: bold;
}

.maint-board h1 {
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 28px;
}

.maint-board ul li {
  margin-bottom: 16px;
}

.line-through {
  text-decoration: line-through;;
}
</style>
